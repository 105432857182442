<template>
  <div id="main-page">
    <NuxtLayout name="navbar">
      <NuxtPage />
    </NuxtLayout>
    <NuxtLayout name="footer" />
  </div>
</template>
<style>
html,
body,
#__nuxt {
    height: 100%;
    margin: 0;
    padding: 0;
}

#main-page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

.page-wrap {
    flex: 1;
}

</style>
